<template>
    <LayoutNew>
        <h1 class="text-center">Update Creator for Notification</h1>
        <MDBCard>
            <MDBLoading v-model="loadingSpinner" />
            <MDBCardHeader class="p-4 pb-3">
                <h4>Content Creator Information</h4>
            </MDBCardHeader>
            <MDBCardBody class="d-flex flex-column" style="gap: 15px">
                <div>
                    <label for="creatorName">Creator Name</label>
                    <MDBInput class="mt-1" type="text" placeholder="Enter Creator Name" v-model="form.creatorName"
                        id="creatorName" />
                </div>
                <div>
                    <label for="videoPageLink">Videos Page Link</label>
                    <MDBInput class="mt-1" type="text" placeholder="Enter Video Page Link" v-model="form.videosPageLink"
                        id="videoPageLink" />
                </div>
                <div class="mt-1">
                    <div>
                        <label for="frequencyType">Select Scan Frequency</label>
                        <select v-model="form.frequencyType" class="did-floating-select mt-1" id="frequencyType">
                            <option v-for="option in frequencyType" :key="option.value" :value="option.value">{{
                                option.text
                            }}
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="form.frequencyType == '2'" class="mt-1">
                    <label for="week">Select Day of the Week</label>
                    <select v-model="form.frequency" class="did-floating-select mt-1" id="week">
                        <option v-for="option in dayOfTheWeek" :key="option.value" :value="option.value">{{ option.text
                            }}
                        </option>
                    </select>
                </div>
                <div v-if="form.frequencyType == '3'" mt-1>
                    <label for="monthly">Select Day of the Week</label>
                    <select v-model="form.frequency" class="did-floating-select mt-1" id="monthly">
                        <option v-for="option in dayOfTheMonth" :key="option.value" :value="option.value">{{ option.text
                            }}
                        </option>
                    </select>
                </div>
            </MDBCardBody>
            <MDBCardFooter class="py-3 d-flex justify-content-end">
                <MDBBtn type="submit" @click="submit" color="primary" size="sm" :disabled="isUpdateBtnDisabled">Update
                    Creator</MDBBtn>
            </MDBCardFooter>
        </MDBCard>
    </LayoutNew>
</template>

<script setup>
import LayoutNew from "./LayoutNew.vue";
import { onBeforeMount, computed, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBCardFooter,
    MDBBtn,
    MDBInput,
    MDBLoading,
} from "mdb-vue-ui-kit";
import axios from "axios";
import { PatchCreatorForNotification } from "@/services/Notifications/PatchCreatorForNotification";
import { useToastStore } from "@/store/toastStore";
import { useTitle } from "@vueuse/core";

useTitle("Update Creator for Notification | CreatorShield");

const toastStore = useToastStore();
const route = useRoute();
const userId = route.params.id;
const tableLoader = ref(false);
let loadingSpinner = ref(false);
const router = useRouter();

const form = reactive({
    creatorName: "",
    videosPageLink: "",
    frequencyType: "",
    frequency: "",
});

const dayOfTheWeek = [
    { text: 'Monday', value: '1' },
    { text: 'Tuesday', value: '2' },
    { text: 'Wednesday', value: '3' },
    { text: 'Thursday', value: '4' },
    { text: 'Friday', value: '5' },
];

const dayOfTheMonth = [
    { text: '1st', value: '1' },
    { text: '15th', value: '15' },
    { text: 'Last', value: '30' },
];

const frequencyType = [
    { text: 'Daily', value: '1' },
    { text: 'Weekly', value: '2' },
    { text: 'Monthly', value: '3' },
];

const isUpdateBtnDisabled = computed(() => form.creatorName === "" || form.videosPageLink === "");

const submit = async () => {
    const formData = {
        id: userId,
        creatorName: form.creatorName,
        videosPageLink: form.videosPageLink,
        frequencyType: form.frequencyType,
        frequency: form.frequency,
    };

    loadingSpinner.value = true;
    const toastSuccess = {
        ...toastStore.toastStatus.success,
        message: "Successfully added!",
    };

    await PatchCreatorForNotification(formData)
        .then(() => {
            toastStore.toastObject = {
                ...toastStore.toastObject,
                ...toastSuccess,
            };
        })
        .catch((response) => {
            if (response.status !== 200 || response.status !== 201) {
                toastStore.toastObject = {
                    ...toastStore.toastObject,
                    ...toastStore.toastStatus.fail,
                };
            }
        })
        .finally(() => {
            loadingSpinner.value = false;
            toastStore.toastObject = {
                ...toastStore.toastObject,
                ...toastSuccess,
            };
            router.push({ name: "DailyVideoUpdatesManagement" });
        });
};

onBeforeMount(async () => {
    tableLoader.value = true;
    try {
        const { data } = await axios.get("api/creatorvideoupdates/" + userId);
        form.creatorName = data.creatorName;
        form.videosPageLink = data.videosPageLink;
        form.frequencyType = data.frequencyType;
        form.frequency = data.frequency;
        tableLoader.value = false;
    } catch (error) {
        toastStore.toastObject = {
            ...toastStore.toastObject,
            ...toastStore.toastStatus.fail,
        };
    }
});
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

/* customized select with floating label */
.did-floating-label-content {
    position: relative;
}

.did-floating-label {
    color: #1e4c82;
    font-size: 12.5px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 6px;
    top: 11px;
    padding: 0 5px;
    background: #fff;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.did-floating-input,
.did-floating-select {
    font-size: 16px;
    display: block;
    width: 100%;
    height: 35px;
    padding: 0 12.5px;
    padding-right: 13px;
    background: #fff;
    color: #6c757d;
    border: 1px solid #adb5bd;
    border-radius: 4px;
    box-sizing: border-box;

    &:focus {
        outline: 1.5px solid #0d6efd;

        ~.did-floating-label {
            color: #0d6efd;
            top: -8px;
            font-size: 12.5px;
        }
    }
}

select.did-floating-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select.did-floating-select::-ms-expand {
    display: none;
}

.did-floating-input:not(:placeholder-shown)~.did-floating-label {
    top: -8px;
    font-size: 12.5px;
}

.did-floating-select:not([value=""]):valid~.did-floating-label {
    top: -8px;
    font-size: 12.5px;
}

.did-floating-select[value=""]:focus~.did-floating-label {
    top: 11px;
    font-size: 13px;
}

.did-floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
    background-position: right 15px top 50%;
    background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {

    .did-floating-input,
    .did-floating-select {
        width: 100%;
    }
}
</style>

import { defineStore } from "pinia";
import { ref } from "vue";

export const useToastStore = defineStore("toast", () => {
	const toastObject = ref({
		state: false,
		message: "",
		color: "",
		icon: "",
		title: "",
	});
	const toastStatus = {
		success: {
			state: true,
			message: "Successfully submitted!",
			color: "success",
			icon: "fas fa-check fa-lg me-2",
			title: "Success!",
		},
		fail: {
			state: true,
			message: "An error occurred during request",
			color: "danger",
			icon: "fas fa-exclamation-circle fa-lg me-2",
			title: "Error!",
		},
	};

	return {
		toastObject,
		toastStatus,
	};
});
